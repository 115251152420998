import { CUMULATIVE_SUM_OPTIONS } from './constants';
import {
  container,
  infoParagraph,
  infoParagraphRadio,
  optionContainer,
} from './styles';
import React, { useEffect, useState } from 'react';

import BuRadio from 'components/UI/BuRadio';
import BuSelect from 'components/UI/BuSelect';
import BuToggle from 'components/UI/BuToggle';
import { MONTH } from 'components/dashboard/Metrics/constants';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricSimple,
  BIMetricSimpleNewborn,
  DataDescriptor,
} from 'components/dashboard/Metrics/metrics.types';
import { MetricDateField } from '../MetricDateField';

interface Props {
  metric: BIMetricSimple | BIMetricSimpleNewborn;
  onAdvanceOptionComplete: (
    complete: boolean,
    advanceOptions: Partial<BIMetricSimple>
  ) => void;
}

export const AdvancedOptions: React.FC<Props> = ({
  metric,
  onAdvanceOptionComplete,
}) => {
  const [cumulativeSum, setCumulativeSum] = useState<boolean>(
    metric.is_cumulative_sum || false
  );
  const [cumulativeSumPeriod, setCumulativeSumPeriod] = useState<string>(
    metric.cumulative_sum_period || MONTH
  );
  const [hierarchyRollupSum, setHierarchyRollupSum] = useState<string>(
    metric.manager_aggregation_type || 'self_revenue'
  );
  const [dateField, setDateField] = useState<{
    field: DataDescriptor | undefined | null;
    complete: boolean;
  }>({ field: metric.date_field || undefined, complete: !!metric.date_field });

  const handleChangeEnableCumulativeSum = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setCumulativeSum(e.target.checked);
  };

  const handleChangeCumulative = (values: string[]): void => {
    setCumulativeSumPeriod(values[0]);
  };

  const handleChangeHierarchyRollupSum = (value: string): void => {
    setHierarchyRollupSum(value);
  };

  const handleChangeDateField = ({
    field,
    complete,
  }: {
    field: DataDescriptor | undefined | null;
    complete: boolean;
  }): void => {
    setDateField({ field, complete });
  };

  useEffect(() => {
    const advanceOptions = {
      is_cumulative_sum: cumulativeSum,
      manager_aggregation_type: hierarchyRollupSum,
      cumulative_sum_period: cumulativeSumPeriod,
      date_field: dateField.field ?? null,
    };

    // check if date field is required.
    const complete =
      (!!(cumulativeSum && cumulativeSumPeriod) || !cumulativeSum) &&
      dateField.complete;
    onAdvanceOptionComplete(complete, advanceOptions);
  }, [hierarchyRollupSum, cumulativeSumPeriod, cumulativeSum, dateField]);

  return (
    <div className={container} data-testing="advanced-options-container">
      <MetricCreateSubTitle>Data Aggregation</MetricCreateSubTitle>
      <div className={optionContainer}>
        <BuRadio
          onChange={(e, { value }: { value: string }) =>
            handleChangeHierarchyRollupSum(value)
          }
          checked={['self_revenue', '', undefined].includes(
            metric.manager_aggregation_type
          )}
          value={'self_revenue'}
          label={'Self Reporting'}
        ></BuRadio>
        <p className={infoParagraphRadio}>
          Report only individual’s own metric
        </p>
      </div>
      <div className={optionContainer} data-testing="manager-level-aggregation">
        <BuRadio
          onChange={(e, { value }: { value: string }) =>
            handleChangeHierarchyRollupSum(value)
          }
          checked={metric.manager_aggregation_type === 'total_team_revenue'}
          value={'total_team_revenue'}
          label={'Total Team Aggregation'}
        ></BuRadio>
        <p className={infoParagraphRadio}>
          Combines individual and the team’s metric, recursively calculated
        </p>
      </div>
      <div className={optionContainer} data-testing="manager-level-aggregation">
        <BuRadio
          onChange={(e, { value }: { value: string }) =>
            handleChangeHierarchyRollupSum(value)
          }
          checked={metric.manager_aggregation_type === 'direct_report_revenue'}
          value={'direct_report_revenue'}
          label={'Direct Report Aggregation'}
        ></BuRadio>
        <p className={infoParagraphRadio}>
          Aggregates only direct report’s metric
        </p>
      </div>
      <MetricCreateSubTitle>Advanced Options</MetricCreateSubTitle>
      <div className={optionContainer}>
        <BuToggle
          checked={cumulativeSum}
          onChange={handleChangeEnableCumulativeSum}
          testingLabel="cumulative-sum-over-time"
        >
          Cumulative sum over time
        </BuToggle>
        <p className={infoParagraph}>
          Shows the metric accumulating over periods of time of your choice when
          pivoted by a date field
        </p>
        {cumulativeSum && (
          <BuSelect
            fullWidth
            secondary
            defaults={[cumulativeSumPeriod]}
            options={CUMULATIVE_SUM_OPTIONS}
            testingLabel="cumulative-sum-selector"
            onChange={handleChangeCumulative}
          />
        )}
      </div>
      <MetricDateField
        metric={metric}
        dateField={metric.date_field}
        onDateFieldChange={handleChangeDateField}
      />
    </div>
  );
};
