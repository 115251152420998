import { WidgetMetricConfiguration } from 'api/RevBiWidget';

import { formatMoney, formatNumber } from 'common/numbers';
import {
  IColumn,
  IRow,
  SortOrder,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { COLUMN_DEFAULTS } from 'components/dashboard/Metrics/Widget/Table/constants';
import { getUnits } from 'components/dashboard/Metrics/Widget/helper';
import { UnitType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricSimple,
  BIMetricsMap,
} from 'components/dashboard/Metrics/metrics.types';
import { getColumnAlignmentByType } from 'utils/aligns';

export type ConfigClickExtraData = {
  subValueClicked?: boolean;
};

/**
 * Returns the format type of the given metric.
 *
 * The function checks for the `column_type` property within `forecast_submission_properties` of the `metric` object.
 * If it's not found, it checks for the `type` property within the `column` of the `metric` object.
 * If neither property is found, it defaults to returning 'number'.
 *
 * @param {BIMetricSimple} metric - The metric object to determine the format type from.
 * @returns {string} - The format type of the metric, either `column_type`, `type`, or 'number'.
 */
export const getMetricFormatType = (metric: BIMetricSimple) => {
  return (
    metric?.forecast_submission_properties?.column_type ?? metric?.column?.type
  );
};

export const getSortingConfiguration = (
  availablePivots: number,
  isFirstPivotDate: boolean
) => {
  if (availablePivots === 1) {
    if (isFirstPivotDate) {
      return {
        firstColumnSortable: false,
        metricsSortable: false,
      };
    } else {
      return {
        firstColumnSortable: true,
        metricsSortable: true,
      };
    }
  } else {
    if (isFirstPivotDate) {
      return {
        firstColumnSortable: true,
        metricsSortable: true,
      };
    } else {
      return {
        firstColumnSortable: true,
        metricsSortable: false,
      };
    }
  }
};

export const getDateExtraHeadersForMetrics = (
  datePivotValues: string[],
  metricsCount: number
) => [
  {
    id: 'Hierarchy',
    label: '',
    field: '',
    type: ColumnTypes.TEXT,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    config: {},
    colSpan: 1,
  },
  ...datePivotValues.map((datePivotValue) => {
    return {
      id: datePivotValue,
      label: datePivotValue,
      field: datePivotValue,
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {},
      colSpan: metricsCount,
    };
  }),
];

export const getMetricsForExtraHeaders = (
  extraHeaders: IColumn[],
  metricColumns: WidgetMetricConfiguration[]
): WidgetMetricConfiguration[] =>
  extraHeaders
    // First header is for the hierarchy
    // We don't need metric columns for that
    // so we remove it
    .slice(1)
    .flatMap((extraHeader) =>
      metricColumns.map((metricColumn) => {
        const fieldForMetric = `${metricColumn.field_name}|${extraHeader.field}`;

        return {
          ...metricColumn,
          field_name: fieldForMetric,
        };
      })
    );

const getFormattedValue = (
  type: string,
  value: ValueProp,
  companyCurrencyCode: string,
  prefix: string,
  sufix: string
) => {
  const signal = (value as number) < 0 ? '-' : '';
  const percentageSign = type === 'percentage' ? '%' : '';
  if (isNaN(value as number)) {
    return '-';
  }

  const formattedNumber = `${signal}${prefix}${formatNumber(
    Math.abs(value as number),
    0
  )}${percentageSign}${sufix}`;

  switch (type) {
    case 'money':
      return formatMoney(companyCurrencyCode, value as number, 0);
    case 'number':
    case 'percentage':
      return formattedNumber;
    default:
      return `${signal}${prefix}${value}${sufix}`;
  }
};

const getSubValueConfig = (metric: WidgetMetricConfiguration) => {
  /**
   * remember if the first pivot is date, we need take care about the |
   * to know which is the real value for that period time
   */
  const postPipeSection = metric.field_name.split('|')[1];

  const metricSubValueConfigs = metric.displayConfig?.subvalues ?? {};
  // `subvalues` is always an object with one key (only one subvalue is supported for now)
  const subValueConfig = Object.values(metricSubValueConfigs)[0];

  if (!subValueConfig) {
    return;
  }

  const isFormulaSubValue =
    subValueConfig.type === 'prefix' || subValueConfig.type === 'suffix';

  let suffix = '';
  let prefix = '';

  if (isFormulaSubValue && subValueConfig.unit) {
    if (subValueConfig.type === 'prefix') {
      prefix = subValueConfig.unit;
    } else if (subValueConfig.type === 'suffix') {
      suffix = subValueConfig.unit;
    }
  }

  const template = subValueConfig.template
    ? `${subValueConfig.template}: {value}`
    : '{value}';

  /**
   * RC-338
   * Updating the field name for not saved booked amount
   * to avoid conflicts with standard sub values.
   */
  const fieldNameUpdated = subValueConfig.field_name.includes(
    'not_saved_booked_amount'
  )
    ? `${Object.keys(metricSubValueConfigs)[0]}$${subValueConfig.field_name}`
    : subValueConfig.field_name;

  const fieldName = postPipeSection
    ? `${fieldNameUpdated}|${postPipeSection}`
    : fieldNameUpdated;

  const type = isFormulaSubValue ? 'number' : subValueConfig.type;

  return {
    fieldName: fieldName,
    type,
    template,
    suffix,
    prefix,
  };
};

export const getMetricColumns = (
  metricsColumns: WidgetMetricConfiguration[],
  metricsInUse: BIMetricsMap,
  isSortable: boolean,
  companyCurrencyCode: string,
  hasDeltas: boolean = false,
  onTableDataClick: (
    column: IColumn,
    row: IRow,
    extraData: ConfigClickExtraData
  ) => void
): IColumn[] =>
  metricsColumns.map((metricColumn) => {
    const metricID = metricColumn.field_name.split('|')?.[0];
    const extraHeaderValue = metricColumn.field_name.split('|')?.[1] ?? '';

    /**
     * RC-163
     * Since by now formula is only money (can be type number but will render
     * as money), forcing it to be right alignment.
     *
     * RC-337
     * Force money type for number type on RevBI.
     */
    const columType =
      metricColumn.metadata?.type === 'formula' ||
      metricColumn.type === 'number'
        ? 'money'
        : metricColumn.type;

    const tableColumn = {
      ...COLUMN_DEFAULTS,
      id: metricColumn.field_name,
      align: getColumnAlignmentByType(columType),
      customColor: metricColumn.displayConfig?.column_highlight_color,
      field: metricColumn.field_name,
      label: metricColumn.display_name,
      sortable: isSortable,
      extraHeader: extraHeaderValue,
    };

    if (['percentage', 'money', 'number'].includes(metricColumn.type)) {
      const metricDefinition = metricsInUse[metricID];
      const prefix = getUnits(UnitType.Prefix, metricDefinition);
      const sufix = getUnits(UnitType.Suffix, metricDefinition);
      const isCountAgg =
        (metricDefinition as BIMetricSimple)?.aggregation_function === 'count';

      // counting is always a number,
      // doesn't matter which kind of column type are you counting.
      // and if type is not defined will set as a number.
      // The correct type should be returned by the API
      const type = isCountAgg
        ? 'number'
        : getMetricFormatType(metricDefinition as BIMetricSimple) ??
          metricColumn.type ??
          'number';

      const subValueConfig = getSubValueConfig(metricColumn);

      const subValueMetric = subValueConfig && {
        relativeField: subValueConfig.fieldName,
        template: subValueConfig.template,
        formatter: (subValue: ValueProp) =>
          getFormattedValue(
            subValueConfig.type,
            subValue,
            companyCurrencyCode,
            subValueConfig.prefix,
            subValueConfig.suffix
          ),
      };

      const hasDelta = hasDeltas && !!metricColumn.displayConfig?.show_deltas;

      const deltaBadge = hasDelta && {
        badge: {
          type: 'subtraction',
          clickable: false,
          relative_fields: [
            metricColumn.field_name,
            `metricDeltas.${metricColumn.field_name}`,
          ],
          skeleton: {
            field: 'metricDeltas.isLoading',
          },
          fieldParser: (value: ValueProp) => {
            // For deltas '-' is a valid value, but we need to return 0 for it
            // so the subtraction will be correct
            if (value === '-') {
              return 0;
            }
            return value;
          },
          formatter: (value: ValueProp) =>
            getFormattedValue(
              type,
              Math.abs(value as number),
              companyCurrencyCode,
              prefix,
              sufix
            ),
        },
      };

      return {
        ...tableColumn,
        type: type,
        config: {
          click: (
            column: IColumn,
            row: IRow,
            extraData: ConfigClickExtraData
          ) => {
            onTableDataClick(column, row, extraData);
          },
          formatter: (value: ValueProp) =>
            getFormattedValue(type, value, companyCurrencyCode, prefix, sufix),

          subValue: {
            ...subValueMetric,
            ...deltaBadge,
          },
        },
      } as IColumn;
    }

    return {
      ...tableColumn,
      type: metricColumn.type === 'date' ? ColumnTypes.TEXT : metricColumn.type,
      config: {},
    } as IColumn;
  });
