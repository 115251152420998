import React, { useEffect, useState } from 'react';

import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import { AdvancedOptions } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/AdvancedOptions';
import {
  ForecastSubmissionOptions,
  ForecastSubmissionOptionsProps,
} from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/ForecastSubmissionOptions/ForecastSubmissionOptions';
import { MetricCreateSelectField } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/MetricCreateSelectField';
import { MetricObjectSelector } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/MetricObjectSelector';
import { TargetFields } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/TargetFields';
import {
  AnalysisType,
  FORECAST_AMOUNT_ACCURACY,
  FORECAST_SUBMISSION,
  FORECAST_SUBMISSION_OBJECTS,
  OPPORTUNITY,
  QUARTER,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import { AggregationFunction } from 'components/dashboard/Metrics/enums';
import {
  BIMetricSimple,
  BIMetricSimpleNewborn,
  BIMetricUnion,
  BIMetricsQueryFilter,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  metric: BIMetricSimple;
  onCompleteMetricInputs: (
    complete: boolean,
    metricInputs: Partial<BIMetricSimple>
  ) => void;
}

export const SimpleMetricsInputs: React.FC<Props> = ({
  metric,
  onCompleteMetricInputs,
}) => {
  const [localMetricObject, setLocalMetricObject] = useState<string>(
    metric.object || OPPORTUNITY
  );

  const [propertyMetric, setPropertyMetric] = useState<{
    complete: boolean;
    metricProperty: Partial<BIMetricSimple>;
  }>();

  const [targetInputs, setTargetInputs] = useState<Partial<BIMetricSimple>>({
    target_period: metric.target_period || undefined,
    target_type: metric.target_type || undefined,
  });

  const [filters, setFilters] = useState<{
    complete: boolean;
    filters: BIMetricsQueryFilter[];
  }>({ complete: !!metric?.filters, filters: metric?.filters ?? [] });

  const [advancedOptions, setAdvancedOptions] = useState<{
    complete: boolean;
    advancedOptions: Partial<BIMetricSimple>;
  }>();

  const [forecastSubmissionOptions, setForecastSubmissionOptions] =
    useState<ForecastSubmissionOptionsProps>({
      calculation: metric.forecast_submission_properties?.calculation,
      manager_aggregation_type: metric.manager_aggregation_type || undefined,
      date_field: metric.date_field,
    });

  const isForecastSubmissionObject =
    FORECAST_SUBMISSION_OBJECTS.includes(localMetricObject);

  const isForecastAmountAccuracy =
    !!metric.forecast_submission_properties?.metric_type &&
    metric.forecast_submission_properties?.metric_type ===
      FORECAST_AMOUNT_ACCURACY;

  const isTargetObject = localMetricObject === TARGET;

  const handleChangeMetricObject = (value: string): void => {
    // changing object type require to clean up the query
    // because different object type allow different columns in the filter or formula
    if (value && value !== localMetricObject) {
      setFilters({ complete: true, filters: [] });

      // forecast submission has it's own options
      // so that's why we have two different places where
      // set the date field to null.
      // it should be null because in case the object has a default
      // it will reset or it will clean the prop once it's saved.
      setForecastSubmissionOptions((prev) => ({
        ...prev,
        date_field: null,
      }));

      setAdvancedOptions((prev) => ({
        complete: false,
        advancedOptions: { ...prev?.advancedOptions, date_field: null },
      }));
      // end of comment

      const isTarget = value === TARGET;
      const isFSObject = value === FORECAST_SUBMISSION;

      setLocalMetricObject(value);

      if (isTarget) {
        setTargetInputs({
          target_type: 'ARR',
          target_period: QUARTER,
        });
      } else {
        setTargetInputs({
          target_type: undefined,
          target_period: undefined,
        });
      }

      setPropertyMetric(
        (prev) =>
          prev && {
            complete: false,
            metricProperty: {
              ...prev.metricProperty,
              date_field: null,
              column: undefined,
              forecast_submission_properties: undefined,
              ...(isTarget && {
                aggregation_function: undefined,
              }),
              ...(!isTarget &&
                !prev.metricProperty.aggregation_function && {
                  aggregation_function: AggregationFunction.Sum,
                }),
              ...(isFSObject
                ? {
                    aggregation_function: undefined,
                  }
                : {}),
            },
          }
      );
    }
  };

  const handleChangePropertyMetric = (
    complete: boolean,
    metricProperty: Partial<BIMetricSimple>
  ): void => {
    setPropertyMetric({ complete, metricProperty });
  };

  // this function makes me thinking we have to improve it...
  // probably wasn't a good idea to move it here...
  const handleChangeConditions = (
    complete: boolean,
    filters: BIMetricsQueryFilter[]
  ) => {
    setFilters({ complete, filters });
  };

  const handleChangeAdvancedOptions = (
    complete: boolean,
    advancedOptions: Partial<BIMetricSimple | BIMetricSimpleNewborn>
  ): void => {
    setAdvancedOptions({ complete, advancedOptions });
  };

  useEffect(() => {
    let inputsReady = false;
    let metricInputObj: Partial<BIMetricUnion> = {
      object: localMetricObject,
      filters: filters.filters,
      ...propertyMetric?.metricProperty,
      ...advancedOptions?.advancedOptions,
    };

    // if one of the mandatory section are incomplete should not notify nothing.
    if (isTargetObject && targetInputs) {
      metricInputObj = {
        ...metricInputObj,
        ...targetInputs,
        // remove not needed fields
        forecast_submission_properties: undefined,
      };
      inputsReady = !!(targetInputs.target_period && targetInputs.target_type);
    } else if (isForecastSubmissionObject) {
      const { metric_type = '', column_type = '' } =
        propertyMetric?.metricProperty.forecast_submission_properties ?? {};

      const {
        calculation = '',
        manager_aggregation_type = '',
        date_field = null,
      } = forecastSubmissionOptions ?? {};

      metricInputObj = {
        ...metricInputObj,
        date_field,
        forecast_submission_properties: {
          metric_type,
          column_type,
          calculation,
        },
        manager_aggregation_type,
        // remove not needed fields
        target_period: undefined,
        target_type: undefined,
      };
      inputsReady = !!propertyMetric?.complete;
    } else if (!isTargetObject) {
      metricInputObj = {
        ...metricInputObj,
        // remove not needed fields
        forecast_submission_properties: undefined,
        target_period: undefined,
        target_type: undefined,
      };
      inputsReady = !!propertyMetric?.complete;
    }

    if (
      metric.analysis_type === AnalysisType.LIVE &&
      !isForecastSubmissionObject
    ) {
      inputsReady = inputsReady && !!advancedOptions?.complete;
    }

    inputsReady = inputsReady && !!localMetricObject && !!filters.complete;
    onCompleteMetricInputs(inputsReady, metricInputObj);
  }, [
    localMetricObject,
    targetInputs.target_period,
    targetInputs.target_type,
    propertyMetric,
    filters,
    advancedOptions,
    forecastSubmissionOptions,
  ]);

  // we need this because FS is not using columns but it works so similar.
  const metricColumn = metric.column ?? {
    name: metric.forecast_submission_properties?.metric_type ?? '',
    label: metric.forecast_submission_properties?.metric_type ?? '',
    type: metric.forecast_submission_properties?.column_type ?? '',
  };

  return (
    <>
      <MetricObjectSelector
        analysisType={metric.analysis_type}
        metricObject={localMetricObject}
        onChangeMetricObject={handleChangeMetricObject}
      />

      {
        /**
         * If the metric allows operations over a specific properties.
         */
        !isTargetObject && (
          <MetricCreateSelectField
            aggregationFunction={metric.aggregation_function}
            analysisType={metric.analysis_type}
            metricColumn={metricColumn}
            metricObject={localMetricObject}
            updatePropertyMetric={handleChangePropertyMetric}
          />
        )
      }

      {
        /**
         * If the metric is related to Targets needs specific
         * fields.
         */
        isTargetObject && (
          <TargetFields
            conditions={metric.filters}
            targetPeriod={targetInputs.target_period}
            targetType={targetInputs.target_type}
            setTargetInputs={setTargetInputs}
          />
        )
      }
      <Conditions
        metric={metric}
        columnFields={[localMetricObject]}
        onCompleteConditions={handleChangeConditions}
      />

      {metric.analysis_type === AnalysisType.LIVE && (
        <>
          {isForecastSubmissionObject ? (
            <ForecastSubmissionOptions
              metric={metric}
              key={`${metric.object}-${metric.forecast_submission_properties?.metric_type}`}
              isForecastAmountAccuracy={isForecastAmountAccuracy}
              forecastSubmissionOptions={forecastSubmissionOptions}
              setForecastSubmissionOptions={setForecastSubmissionOptions}
            />
          ) : (
            <AdvancedOptions
              metric={metric}
              onAdvanceOptionComplete={handleChangeAdvancedOptions}
            />
          )}
        </>
      )}
    </>
  );
};
