import styled from '@emotion/styled';

export const Header = styled.h3({
  paddingTop: '15px',
  fontFamily: 'var(--bu-font-medium)',
  fontSize: '16px',
  color: 'var(--bu-gray-900)',
});

export const value = styled.span({
  fontFamily: 'var(--bu-font-medium)',
  fontSize: '24px',
  color: 'var(--bu-gray-900)',
});

export const clickedValue = styled.div({
  display: 'block',
  fontSize: '14px',
  fontFamily: 'var(--bu-font-regular)',
  padding: '5px 0 5px 0',
});

export const formulaBlock = styled.div({
  margin: '16px 0 16px 0',
});

export const formulaTitle = styled.span({
  display: 'block',
  fontSize: '12px',
  color: 'var(--bu-gray-700)',
  paddingBottom: '5px',
});

export const conditionsBlock = styled.div({
  margin: '16px 0 16px 0',
});

export const ModalWrapper = styled.div({
  paddingBottom: '10px',
});
