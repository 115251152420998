import moment from 'moment';

export const getStartDateForChangeInterval = (
  changeIntervalValue: string,
  dateUsedAsBase: Date = new Date(),
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const momentDate = moment(dateUsedAsBase).tz(timezone);
  switch (changeIntervalValue) {
    case 'L7D':
      return momentDate.subtract(7, 'days');
    case 'L14D':
      return momentDate.subtract(14, 'days');
    case 'L30D':
      return momentDate.subtract(30, 'days');
    case 'L60D':
      return momentDate.subtract(60, 'days');
    case 'L3M':
      return momentDate.subtract(3, 'months');
    case 'SQS':
      return momentDate.startOf('quarter');
    default:
      return momentDate;
  }
};
