import Highcharts, { BreadcrumbOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { HierarchicalWidget } from '../hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';

import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  BIMetricToChartType,
  BIMetricsMap,
  BIWidget,
  BIMetricSimple,
} from 'components/dashboard/Metrics/metrics.types';
import { getHighChartOptions } from './WidgetChart.helper';
import { OnChartDataClick } from './WidgetChart.types';
import { getMetricFormatType } from '../Table/helpers/columnsHelpers';

HighchartsBoost(Highcharts);

interface Props {
  readonly metricToChartType: BIMetricToChartType[];
  readonly metricsInUse: BIMetricsMap;
  readonly widget: Partial<BIWidget>;
  readonly widgetData: HierarchicalWidget;
  onDataClick: OnChartDataClick;
  updateTotal: (levelObject: BreadcrumbOptions[]) => void;
}

export const WidgetChart: React.FC<Props> = ({
  metricToChartType,
  metricsInUse,
  widget,
  widgetData,
  onDataClick,
  updateTotal,
}) => {
  const { currency: companyCurrencyCode, hierarchyAlias } =
    useContext(RevBISettingsContext);

  const chartRef =
    useRef<{
      chart: Highcharts.Chart;
      container: React.RefObject<HTMLDivElement>;
    }>(null);

  /**
   * until now, chart uses columnConfiguration only to know
   * how represent each value.
   */
  widgetData.metricConfigurations.map((column) => {
    const metric = metricsInUse[column.field_name];
    column.type = getMetricFormatType(metric as BIMetricSimple) ?? column.type;
    return column;
  });

  const chartOptions: Highcharts.Options | undefined = useMemo(
    () =>
      getHighChartOptions(
        widgetData,
        companyCurrencyCode,
        metricToChartType,
        hierarchyAlias,
        onDataClick,
        updateTotal
      ),
    [
      companyCurrencyCode,
      widgetData,
      JSON.stringify(metricsInUse),
      JSON.stringify(metricToChartType),
    ]
  );

  useEffect(() => {
    const element = chartRef?.current?.container.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      chartRef.current?.chart.reflow();
    });

    observer.observe(element);

    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
  }, []);

  const component = useMemo(
    () => (
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        key={JSON.stringify(metricToChartType)}
        ref={chartRef}
        containerProps={{
          'data-testing': 'widget-chart-container',
          style: { height: '100%' },
        }}
      />
    ),
    [JSON.stringify(metricsInUse), JSON.stringify(metricToChartType)]
  );

  return chartOptions ? component : null;
};
