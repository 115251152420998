import {
  ACCOUNT,
  OPPORTUNITY,
  OPPORTUNITY_SPLIT,
} from 'components/dashboard/Metrics/constants';

export const SUPPORTED_DRILLDOWN_OBJECTS = [
  OPPORTUNITY,
  ACCOUNT,
  OPPORTUNITY_SPLIT,
];
