import classNames from 'classnames';
import { css } from 'emotion';
import * as R from 'ramda';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { PopupProps } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatMoney, getCurrencySymbol } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import {
  CRMMetadataType,
  IColumn,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
  ValueProp,
  ValueType,
  isDisable,
  isEditable,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import ArrowValueCell from 'components/UI/common/TypedTable/renderers/common/ArrowValueCell';
import DeltaValueCell, {
  getValueFromDelta,
} from 'components/UI/common/TypedTable/renderers/common/DeltaValueCell';
import { OppSplitTooltip } from 'components/UI/common/TypedTable/renderers/common/OppSplitTooltip';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import * as s from 'components/UI/common/TypedTable/styles';
import Badge from 'components/dashboard/Forecast/Dashboard/Badge';
import { getRawValue } from 'components/dashboard/ForecastRollUps/helpers/rowValueExtractors';
import { useHandleCellStatus } from 'components/hooks/useHandleCellStatus';
import * as selectors from 'selectors';
import { isOppSplitEnabled } from 'selectors';

export interface SimpleCellConfig extends TypedTableCellConfig {
  min?: number;
  max?: number;
  step?: number;
  click?: (
    column?: IColumn,
    row?: IRow,
    extraData?: { [key: string]: ValueProp }
  ) => void;
  saveOnBlur?: boolean;
  getPrefix?: any;
}

const hideArrowsSpinners = css`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const currencySpan = css`
  background-color: #eeeeee;
  text-align: center;
  padding: 0 8px;
`;

const borderRadius = css`
  border-radius: 2px;
  overflow: hidden;
`;

const editControlContainer = css`
  display: flex;
  align-items: center;
`;

const multiCurrencyIcon = css`
  position: relative;
  font-size: 20px;
`;

const inputStyle = css`
  border: 0;
  border-left: 1px solid var(--bu-primary-500);
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-block;
  flex: 1;
  width: 100%;
`;

const editableCellBorder = css`
  border: 1px solid transparent;
  outline: none;
  box-sizing: border-box;

  tr:hover &,
  tr.show-controls & {
    border: 1px solid var(--bu-gray-400);
    box-sizing: border-box;
    background-color: var(--bu-white);

    &:hover {
      border-color: var(--bu-gray-500);
    }
  }

  &.disabled {
    border-color: #edf0f2;
    opacity: 0.45;
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }
`;

const alignRight = css`
  text-align: right;
  justify-content: right;
`;

const alignCenter = css`
  text-align: center;
  justify-content: center;
`;

const inputCellBorder = css`
  border-width: 1px;
  border-style: solid;
  border-color: var(--bu-primary-500);
  border-image: initial;
  line-height: 26px;
`;

const textAlignmentToMarging = {
  left: '6px 10% 0 0',
  right: '6px 0 0 10%',
  center: '6px 5% 0 5%',
};

export const target_seller_main_line_block = (
  textAlignment: 'left' | 'right' | 'center' = 'left'
) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${textAlignmentToMarging[textAlignment]};
`;

export const target_seller_main_line = css`
  flex: 1;
  height: 4px;
  border-radius: 2px;
  background: var(--bu-gray-400);
  overflow: hidden;
  position: relative;
`;

export const target_seller_line_fill = css`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  background: var(--bu-primary-500);
  height: 100%;
  max-width: 100%;
`;

export const target_seller_main_line_percent = css`
  margin-left: 5px;
  color: var(--bu-gray-900);
  font-size: 0.85em;
`;

const subValue_block = css`
  color: var(--bu-gray-900);
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const cursorClick = css`
  cursor: pointer;
`;

const badge_block = css`
  margin-top: -5px;
`;

const oppSplitCellOrMulticurrencyOrHasPrefix = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 10px;
`;

const badgePadding = css`
  margin-bottom: 6px;
`;

const defaultFormatter = (value: ValueProp) => `${value}`;
const defaultClick = () => {};

type INPUT_TYPE = 'number' | 'text';

const INPUT_TYPE_NUMBER = 'number' as INPUT_TYPE;
const INPUT_TYPE_TEXT = 'text' as INPUT_TYPE;

const isNumberType = (type: INPUT_TYPE): type is 'number' =>
  type === INPUT_TYPE_NUMBER;

const progressBarPercent = (
  fieldValue: ValueProp,
  relativeFieldValue: ValueProp
) => `${Math.round((Number(fieldValue) / Number(relativeFieldValue)) * 100)}%`;

const SimpleCell = ({
  column,
  row,
  rows,
  onChange,
  status,
}: IDataCellProps) => {
  const cellIsDisabled = isDisable(column, row, rows);
  const rawValue = getRawValue(column.field, row) ?? '-';

  const userCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const companyCurrencyCode = useSelector(selectors.getCompanyCurrency);

  const oldValue = column.delta
    ? (getValueFromDelta(rawValue) as ValueProp)
    : rawValue;

  const varType = [
    ColumnTypes.NUMBER,
    ColumnTypes.MONEY,
    ColumnTypes.PERCENT,
    ColumnTypes.CORPORATE_CURRENCY,
  ].includes(column.type)
    ? INPUT_TYPE_NUMBER
    : INPUT_TYPE_TEXT;

  const config: SimpleCellConfig = {
    formatter: defaultFormatter,
    click: defaultClick,
    saveOnBlur: true,
    ...(column.config as SimpleCellConfig),
  };

  const { progressBar } = config;
  let relativeFieldValue;

  if (progressBar && progressBar.relativeField) {
    const relativeFieldRawValue = getRawValue(
      progressBar.relativeField,
      row
    ) as ValueProp;
    relativeFieldValue = column.delta
      ? (getValueFromDelta(relativeFieldRawValue) as ValueProp)
      : relativeFieldRawValue;
  }

  const isSubmittedForecast =
    (column?.config?.tooltip as unknown as { name: string })?.name ===
    'submitted_forecast';

  let isUserHasOtherCurrency =
    row.Currency || companyCurrencyCode !== userCurrencyCode;

  if (
    row.Currency === userCurrencyCode ||
    row.Currency === companyCurrencyCode
  ) {
    isUserHasOtherCurrency = false;
  }

  const crmMetadata = getRawValue('crm_metadata', row) as CRMMetadataType;
  const currency =
    column.type === ColumnTypes.CORPORATE_CURRENCY ||
    !column.config.isMulticurrency
      ? userCurrencyCode
      : crmMetadata?.currency || userCurrencyCode;

  const exchangeRate =
    column.type === ColumnTypes.CORPORATE_CURRENCY
      ? null
      : getRawValue('exchange_rate', row) || null;

  const { subValue } = config;

  let relativeFieldSubValue: ReactNode;
  let badgeValue: number | undefined = undefined;

  let badge: ReactNode;

  if (subValue) {
    if (subValue.relativeField) {
      const relativeFieldSubRawValue = getRawValue(
        subValue.relativeField,
        row
      ) as ValueProp;

      if (
        typeof relativeFieldSubRawValue !== 'undefined' &&
        relativeFieldSubRawValue !== null
      ) {
        const fomatterForSubvalue =
          config?.subValue?.formatter || config.formatter;

        const formattedSubValue = fomatterForSubvalue?.(
          relativeFieldSubRawValue
        );
        relativeFieldSubValue =
          subValue.template && typeof formattedSubValue === 'string'
            ? subValue.template.replace('{value}', formattedSubValue)
            : formattedSubValue;
      }
    }

    if (subValue.badge) {
      const { clickable = true, useMainClickEvent = true } = subValue.badge;

      const relativeFieldsSubRawValue = subValue.badge.relative_fields.map(
        (relativeField) => {
          return getRawValue(relativeField, row);
        }
      );

      const allDashes = relativeFieldsSubRawValue.every((item) => item === '-');

      if (allDashes) {
        badgeValue = undefined;
      } else {
        const relativeFieldsSubRawValueAsNumber = relativeFieldsSubRawValue.map(
          (item) =>
            subValue.badge?.fieldParser
              ? (subValue.badge.fieldParser(item) as number)
              : (item as number)
        );

        const firstItem = relativeFieldsSubRawValueAsNumber.shift();

        badgeValue = relativeFieldsSubRawValueAsNumber.reduce((acc, item) => {
          switch (subValue.badge?.type) {
            case 'addition':
              return acc + item;
            case 'subtraction':
              return acc - item;
            default:
              return acc;
          }
        }, firstItem || 0);
      }

      const loading = subValue.badge?.skeleton
        ? (getRawValue(subValue.badge.skeleton.field, row) as boolean)
        : false;

      const valueType = subValue.badge?.valueTypeField
        ? (getRawValue(subValue.badge.valueTypeField, row) as ColumnTypes)
        : column.type;

      let badgeClick;

      if (clickable) {
        if (useMainClickEvent) {
          badgeClick = () => {
            config.click!(column, row, {
              delta: badgeValue,
              showDelta: true,
            });
          };
        } else {
          badgeClick = () => {
            subValue.badge?.click!(column, row, {
              delta: badgeValue,
              showDelta: true,
            });
          };
        }
      }
      badge = (
        <div className={badge_block}>
          <Badge
            onClick={badgeClick}
            valueType={valueType}
            value={badgeValue}
            isLoading={loading}
            configFormatter={subValue.badge?.formatter}
          />
        </div>
      );
    }
  }

  const [isEdit, setEdit] = useState(false);
  const [newValue, setNewValue] = useState(oldValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const { showStatus } = useHandleCellStatus(status);

  useEffect(() => {
    if (isEdit && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const triggerChange = (currentValue?: string) => {
    if ((newValue !== oldValue || currentValue !== oldValue) && onChange) {
      const valueOnChange =
        currentValue === '' ? null : currentValue || (newValue as ValueType);
      onChange(column, row, valueOnChange);
    }
  };

  const handleLabelKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      cellIsDisabled ||
      event.defaultPrevented ||
      event.altKey ||
      event.ctrlKey
    ) {
      return;
    }

    switch (true) {
      case ['Enter', 'Space', ' '].includes(event.key):
        startEdit();
    }
  };

  const startEdit = () => {
    setNewValue(oldValue);
    setEdit(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.defaultPrevented || event.altKey || event.ctrlKey) {
      return;
    }

    const { key } = event;

    switch (true) {
      case ['Enter'].includes(key):
        setEdit(false);
        setNewValue(inputRef.current!.value);
        triggerChange(inputRef.current!.value);
        break;
      case ['Escape', 'Esc'].includes(key):
        setNewValue(oldValue);
        setEdit(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChangedValue = isNumberType(varType)
      ? config.isCoverage
        ? parseFloat(event.target.valueAsNumber.toFixed(1))
        : isNaN(event.target.valueAsNumber)
        ? event.target.value
        : event.target.valueAsNumber
      : event.target.value;

    setNewValue(newChangedValue);
  };

  const handleClick = () => {
    config.click!(column, row, { delta: badgeValue });
  };

  const handleBlur = () => {
    // We only accept saving null values on enter
    const newValueIsValid = newValue !== undefined && newValue !== '';

    if (newValueIsValid) {
      if (config.saveOnBlur) {
        triggerChange(newValue as string);
      } else {
        triggerChange();
      }
    }

    setEdit(false);
  };

  const selectAll = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const getPlaceholder = (type: string) => {
    switch (type) {
      case 'text':
        return '-';
      case 'number':
        return 0;
      case 'money':
        return formatMoney(currency, 0, 0);
    }
  };

  const isOppSplitFlagEnabled = useSelector(isOppSplitEnabled);
  const splitted_fields = (row?.splitted_fields ?? []) as string[];
  const fieldName = column.field.split('.').pop() ?? '';

  const isColumnSplited =
    splitted_fields.includes(fieldName) && isOppSplitFlagEnabled;

  const OppSplitColumnTooltip = isColumnSplited ? (
    <OppSplitTooltip
      dealId={row._id as string}
      fieldName={fieldName}
      crmField={column.crm_field}
      rowUserName={(row.account_executive as string) || (row.owner as string)}
    />
  ) : null;

  let prefixNode: ReactNode;
  if (config.getPrefix) {
    prefixNode = config.getPrefix(row);
  }

  const placeholder = getPlaceholder(column.type);
  const displayValue = config.formatter!(
    ['money', 'number'].includes(column.type) &&
      !isNaN(oldValue as number) &&
      oldValue !== null
      ? Number(oldValue)
      : oldValue,
    currency,
    exchangeRate
  );

  const showMulticurrencyIcon =
    isSubmittedForecast && isUserHasOtherCurrency && displayValue !== '-';

  const cellIsEditable = isEditable(column, row, rows) && !isColumnSplited;

  const isNotAvailable =
    (R.isNil(oldValue) || R.isEmpty(oldValue)) && !isNumberType(varType);

  const className = config.getClassName
    ? config.getClassName(oldValue, row)
    : config.className;
  let tooltipValue: ReactNode = column.showTooltip && displayValue;
  let tooltipPosition: PopupProps['position'];
  let tooltipHoverable: boolean | undefined;
  const isClickable = defaultClick !== config.click;

  if (config.tooltip) {
    tooltipValue = config.tooltip.getTooltip
      ? config.tooltip.getTooltip(
          oldValue,
          (config.tooltip.relativeFields || []).reduce(
            (acc, relativeField) => ({
              ...acc,
              [relativeField]: getRawValue(relativeField, row) as ValueProp,
            }),
            { deltaValue: badgeValue }
          )
        )
      : (getRawValue(config.tooltip.relativeField || '', row) as ValueProp);
    tooltipPosition = config.tooltip.position;
    tooltipHoverable = config.tooltip.hoverable;
  }

  const children = (relativeFieldValue: ValueProp) => {
    return (
      <>
        {cellIsDisabled && (
          <div
            className={classNames(
              s.fluid,
              s.textOverflow,
              editableCellBorder,
              'disabled'
            )}
          >
            {isNotAvailable ? (
              <NotAvailableCell placeholder={placeholder} />
            ) : (
              <TooltipWrapper
                tooltip={tooltipValue}
                position={tooltipPosition}
                hoverable={tooltipHoverable}
              >
                <div
                  className={classNames(className, {
                    [alignRight]: column.align === 'right',
                    [alignCenter]: column.align === 'center',
                    hoverable: isClickable,
                  })}
                >
                  {displayValue}
                </div>
              </TooltipWrapper>
            )}
          </div>
        )}
        {((!cellIsDisabled && !cellIsEditable) || isColumnSplited) && (
          <div className={classNames(s.fluid, s.textOverflow)}>
            {isNotAvailable ? (
              <NotAvailableCell placeholder={placeholder} />
            ) : (
              <>
                <TooltipWrapper
                  tooltip={tooltipValue}
                  position={tooltipPosition}
                  hoverable={tooltipHoverable}
                >
                  <div
                    className={classNames(className, {
                      [alignRight]: column.align === 'right',
                      [alignCenter]: column.align === 'center',
                      [cursorClick]:
                        config.click !== defaultClick || tooltipHoverable,
                      [oppSplitCellOrMulticurrencyOrHasPrefix]:
                        isColumnSplited ||
                        showMulticurrencyIcon ||
                        !!prefixNode,
                      hoverable: isClickable,
                    })}
                    onClick={handleClick}
                  >
                    {displayValue}

                    {prefixNode}

                    {showMulticurrencyIcon && (
                      <BuIcon
                        name={BoostUpIcons.MultiCurrency}
                        className={multiCurrencyIcon}
                        color="var(--bu-primary-500)"
                      />
                    )}

                    {OppSplitColumnTooltip}
                  </div>
                </TooltipWrapper>
              </>
            )}
          </div>
        )}
        {!cellIsDisabled &&
          cellIsEditable &&
          (!isEdit ? (
            <div
              onClick={startEdit}
              className={classNames(
                s.fluid,
                s.textOverflow,
                editableCellBorder,
                {
                  [(status && status.status) || '']: showStatus,
                }
              )}
              role="button"
              tabIndex={0}
              aria-pressed={false}
              onKeyDown={handleLabelKeyDown}
            >
              {isNotAvailable ? (
                <NotAvailableCell placeholder={placeholder} />
              ) : (
                <TooltipWrapper
                  tooltip={tooltipValue}
                  position={tooltipPosition}
                  hoverable={tooltipHoverable}
                >
                  <div
                    className={classNames(className, {
                      [alignRight]: column.align === 'right',
                      [alignCenter]: column.align === 'center',
                      hoverable: isClickable,
                    })}
                  >
                    {displayValue}
                  </div>
                </TooltipWrapper>
              )}
            </div>
          ) : (
            <div
              className={classNames(
                s.fluid,
                borderRadius,
                inputCellBorder,
                editControlContainer
              )}
            >
              {config.isMoney && !config.isCoverage && (
                <div className={currencySpan}>
                  {getCurrencySymbol(currency)}
                </div>
              )}
              <input
                ref={inputRef}
                type={varType}
                className={classNames(inputStyle, hideArrowsSpinners, status, {
                  [alignRight]: column.align === 'right',
                  [alignCenter]: column.align === 'center',
                })}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onFocus={selectAll}
                onBlur={handleBlur}
                value={newValue as string | number | undefined}
                min={config.min}
                max={config.max}
                step={config.step}
                data-testing="txt_field"
              />
            </div>
          ))}
        {progressBar && !!relativeFieldValue && (
          <div
            className={classNames(
              'line',
              target_seller_main_line_block(column.align)
            )}
          >
            <div className={target_seller_main_line}>
              <div
                style={{
                  width: progressBarPercent(oldValue, relativeFieldValue),
                }}
                className={target_seller_line_fill}
              />
            </div>
            <div className={target_seller_main_line_percent}>
              {progressBarPercent(oldValue, relativeFieldValue)}
            </div>
          </div>
        )}
        {subValue && !!badge && (
          <div
            className={classNames(subValue_block, subValue.className, {
              [badgePadding]: !!relativeFieldSubValue,
            })}
          >
            {badge}
          </div>
        )}
        {subValue && !!relativeFieldSubValue && (
          <div
            className={classNames(subValue_block, subValue.className, {
              [cursorClick]: config.click !== defaultClick,
            })}
            onClick={() => {
              config.click!(column, row, { subValueClicked: true });
            }}
          >
            {relativeFieldSubValue}
          </div>
        )}
      </>
    );
  };

  const isArrowColumn = column.config.subValue?.arrow !== undefined;

  return (
    <>
      {!isArrowColumn ? (
        <DeltaValueCell
          column={column}
          row={row}
          rows={rows}
          formatter={config.formatter}
          formatterExtraOptions={[currency, exchangeRate]}
        >
          {children(relativeFieldValue)}
        </DeltaValueCell>
      ) : (
        <ArrowValueCell column={column} row={row} rows={rows}>
          {children(relativeFieldValue)}
        </ArrowValueCell>
      )}
    </>
  );
};

export default SimpleCell;
