import React from 'react';
import { useDispatch } from 'react-redux';

import * as s from './styles';

import { shortNumberWithConfig } from 'common/numbers';
import SyntheticFormulaPreview from 'components/UI/BuFormulaTextField/SyntheticFormulaPreview';
import BuMetricFiltersDescription from 'components/UI/BuMetricFiltersDescription';
import TypedTable from 'components/UI/common/TypedTable/TypedTable';
import { getUnits } from 'components/dashboard/Metrics/Widget/helper';
import {
  OpenRevBiDrilldownModal,
  useOpenRevBiDrilldownModal,
  useRevBiDrilldownModalWithScheme,
} from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import { REVBI_TOTALS_DECIMALS_CONFIG } from 'components/dashboard/Metrics/constants';
import { UnitType } from 'components/dashboard/Metrics/enums';
import {
  useFetchMetrics,
  useOpenModalByMetricCallback,
  useTableColumnBuilder,
  useTableData,
} from './hooks';

export const SyntheticMetricModal: React.FC = () => {
  const dispatch = useDispatch();

  const { markShouldReFetchOnClose, getProps } =
    useRevBiDrilldownModalWithScheme('/revbi-formula-modal');

  const props = getProps();

  const openRevBiDrilldownModal = useOpenRevBiDrilldownModal();

  const { simpleMetricsUsed } = useFetchMetrics(props.valuesByMetrics);

  const openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal = (
    modalConfig
  ) => {
    openRevBiDrilldownModal(modalConfig, () => {
      markShouldReFetchOnClose();
    });
  };

  const { handleMetricClick } = useOpenModalByMetricCallback(
    dispatch,
    openRevBiDrilldownModalWithOnClose
  );

  const hasDelta = props.valuesByMetrics.some(
    (metric) => !!metric.deltaValue || metric.deltaValue === '-'
  );

  /**
   * this inyect the drilldownTableSettings inside the column because for reason
   * we're facing an issue only when you click on an chart.
   */
  const { columns: tableColumns } = useTableColumnBuilder(
    JSON.parse(JSON.stringify(props.drilldownTableSettings ?? {})),
    handleMetricClick,
    hasDelta
  );

  const { tableData } = useTableData(
    props.valuesByMetrics,
    simpleMetricsUsed,
    props.drilldownParams
  );

  const value = Number(props.selectedValue?.y);
  const signal = value < 0 ? '-' : '';
  const prefix = getUnits(UnitType.Prefix, props.metric);
  const sufix = getUnits(UnitType.Suffix, props.metric);
  const shortNumber = shortNumberWithConfig(REVBI_TOTALS_DECIMALS_CONFIG);
  const displayValue = isNaN(value)
    ? '-'
    : `${signal}${prefix}${shortNumber(Math.abs(value))}${sufix}`;

  const metricDisplayNames = Object.fromEntries(
    props.valuesByMetrics.map((metric) => [metric.id, metric.metricName])
  );

  return (
    <s.ModalWrapper>
      <s.Header>{props.title}</s.Header>

      <s.clickedValue>
        {props.selectedValue?.pivot1Id}{' '}
        {props.selectedValue?.pivot2Id && `& ${props.selectedValue?.pivot2Id}`}
      </s.clickedValue>

      <s.value>{displayValue}</s.value>

      <s.formulaBlock>
        <s.formulaTitle>Formula:</s.formulaTitle>
        <SyntheticFormulaPreview
          formula={props.formula}
          metricDisplayNames={metricDisplayNames}
        />
      </s.formulaBlock>

      {props.conditions && (
        <s.conditionsBlock>
          <BuMetricFiltersDescription filters={props.conditions} />
        </s.conditionsBlock>
      )}

      <TypedTable.Border>
        <TypedTable
          columns={tableColumns}
          data={tableData}
          width="100%"
          isModal
          fullscreen
        />
      </TypedTable.Border>
    </s.ModalWrapper>
  );
};
