import {
  WidgetMetricConfiguration,
  WidgetPivotConfiguration,
} from 'api/RevBiWidget';
import {
  AxisLabelsFormatterContextObject,
  BreadcrumbOptions,
  PointClickEventObject,
  PointOptionsObject,
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesOptionsType,
  TooltipFormatterContextObject,
  YAxisLabelsOptions,
  YAxisOptions,
} from 'highcharts';
import { ascend, compose, mergeDeepRight, path, sort } from 'ramda';
import { PathAwareHierarchicalWidgetNode } from '../hooks/useExpandableWidgetTable/useExpandableWidgetTable.helper';
import { HierarchicalWidget } from '../hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import {
  ChartClickedData,
  ChartPivotConfiguration,
  MetricChartConfiguration,
  OnChartDataClick,
  PieChartConfiguration,
  RevBiCustomPoint,
  RevBiCustomSeries,
  SimpleMetricChartConfiguration,
  StackedMetricChartConfiguration,
  isPieChart,
  isSimpleVisualizationChart,
  isStackedVisualizationChart,
} from './WidgetChart.types';

import { HighchartsColors } from 'common/highcharts-helpers';
import { formatAmount, formatNumber, formatPercentage } from 'common/numbers';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import { BIMetricToChartType } from 'components/dashboard/Metrics/metrics.types';

const someIsPieChart = (metrics: MetricChartConfiguration[]) =>
  metrics.some((m) => m.chartType === VisualizationType.Pie);

const someIsStackedChart = (metrics: MetricChartConfiguration[]) =>
  metrics.some((m) => m.chartType === VisualizationType.ColumnStacked);

const someIsSimpleChart = (metrics: MetricChartConfiguration[]) =>
  metrics.some(
    (m) =>
      m.chartType === VisualizationType.Column ||
      m.chartType === VisualizationType.Line
  );

const filterSimpleVisualizationCharts = (
  metrics: MetricChartConfiguration[]
): SimpleMetricChartConfiguration[] =>
  metrics.filter(isSimpleVisualizationChart);

const filterStackedVisualizationCharts = (
  metrics: MetricChartConfiguration[]
): StackedMetricChartConfiguration[] =>
  metrics.filter(isStackedVisualizationChart);

const findPieChart = (
  metrics: MetricChartConfiguration[]
): PieChartConfiguration | undefined => metrics.find(isPieChart);

const getChartTypeForMetric = (
  metricId: string,
  metricToChartType: BIMetricToChartType[]
): VisualizationType => {
  const metricType = metricToChartType.find((ch) => ch.metricId === metricId);
  return metricType?.chartType || VisualizationType.Column;
};

const getFieldSafeValue = (
  node: PathAwareHierarchicalWidgetNode,
  fieldName: string
) => {
  const valueOnNode = Number(node[fieldName]);

  if (isNaN(valueOnNode)) {
    return 0;
  }

  return valueOnNode;
};

const addYAxisIndex = <T extends MetricChartConfiguration>(
  metrics: T[]
): T[] => {
  const typeToYIndex = Array.from(new Set(metrics.map((m) => m.type)));
  return metrics.map((m) => ({ ...m, yAxis: typeToYIndex.indexOf(m.type) }));
};

const getYAxisForChart = (
  metrics: MetricChartConfiguration[]
): YAxisOptions[] => {
  const groupedByType = metrics.reduce((acc, metric) => {
    if (!acc[metric.type]) {
      acc[metric.type] = [];
    }
    acc[metric.type].push(metric);
    return acc;
  }, {} as Record<string, MetricChartConfiguration[]>);

  const yAxis = Object.values(groupedByType).map((group, idx) => {
    const firstMetric = group[0];
    const thereIsOnlyOneMetric = group.length === 1;
    return {
      title: {
        text: thereIsOnlyOneMetric ? firstMetric.display_name : '',
      },
      opposite: idx > 0,
    };
  });

  return yAxis;
};

const getPieChartConfiguration = (
  data: PathAwareHierarchicalWidgetNode[],
  metric: PieChartConfiguration,
  pivot: ChartPivotConfiguration
): Highcharts.Options => {
  const pivotField = pivot.field_name;
  const metricField = metric.field_name;
  const pieSeries = data.map((node) => {
    const value = getFieldSafeValue(node, metricField);
    const isNegative = value < 0;
    return {
      color: HighchartsColors.mapColorBasedOnLabel(node[pivotField], true),
      name: node[pivotField],
      y: Math.abs(value),
      custom: {
        isNegative,
        pivot1Value: node[pivotField],
      },
    };
  });

  return {
    title: {
      text: '',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        type: 'pie',
        data: pieSeries,
        innerSize: '50%',
        custom: {
          valueType: metric.type,
          metricId: metric.field_name,
        },
      },
    ],
  };
};

const getDrilldownIdForSimpleSeries = (
  hierarchyPath: string,
  node: PathAwareHierarchicalWidgetNode,
  pivotField: string,
  metricField: string
) => {
  if (!node.children || node.children.length === 0) {
    return;
  }
  if (!hierarchyPath) {
    return `${node[pivotField]}-${metricField}`;
  }

  return `${hierarchyPath}-${node[pivotField]}-${metricField}`;
};

const getIdForSimpleSeries = (
  hierarchyPath: string,
  metricField: string
): string => {
  if (!hierarchyPath) {
    return metricField;
  }

  return `${hierarchyPath}-${metricField}`;
};

export const buildSimpleSeries = (
  pivot: WidgetPivotConfiguration,
  metric: SimpleMetricChartConfiguration,
  data: PathAwareHierarchicalWidgetNode[],
  hierarchyPath: string = ''
): SeriesColumnOptions | SeriesLineOptions => {
  const pivotField = pivot.field_name;
  const metricField = metric.field_name;
  const seriesData = data.map((node) => ({
    y: getFieldSafeValue(node, metricField),
    name: node[pivotField],
    drilldown: getDrilldownIdForSimpleSeries(
      hierarchyPath,
      node,
      pivotField,
      metricField
    ),
    custom: {
      pivot1Value: node[pivotField],
    },
  }));

  return {
    id: getIdForSimpleSeries(hierarchyPath, metricField),
    name: metric.display_name,
    color: HighchartsColors.mapColorBasedOnLabel(metric.display_name, true),
    data: seriesData,
    type: metric.chartType,
    custom: {
      valueType: metric.type,
      metricId: metric.field_name,
      pivot2Value: hierarchyPath || undefined,
    },
    yAxis: metric.yAxis,
  };
};

export const buildDrilldownSimpleSeries = (
  data: PathAwareHierarchicalWidgetNode[],
  pivot: ChartPivotConfiguration,
  metrics: SimpleMetricChartConfiguration[],
  hierarchyPath: string
): Array<SeriesColumnOptions | SeriesLineOptions> => {
  const nodeSeries = metrics.map((metric) =>
    buildSimpleSeries(pivot, metric, data, hierarchyPath)
  );

  const childrenDrilldown = data
    .flatMap(
      (node) =>
        node.children &&
        buildDrilldownSimpleSeries(
          node.children,
          pivot,
          metrics,
          `${hierarchyPath}-${node[pivot.field_name]}`
        )
    )
    .filter((s) => s) as Array<SeriesColumnOptions | SeriesLineOptions>;

  return [...nodeSeries, ...childrenDrilldown];
};

const removePivot2FromSeries = (
  series: SeriesColumnOptions | SeriesLineOptions
): SeriesColumnOptions | SeriesLineOptions => ({
  ...series,
  custom: {
    ...series.custom,
    pivot2Value: undefined,
  },
});

export const getSimpleVisualizationChart = (
  metrics: SimpleMetricChartConfiguration[],
  pivots: ChartPivotConfiguration[],
  data: PathAwareHierarchicalWidgetNode[]
): Highcharts.Options => {
  const firstPivot = pivots[0];

  const series = metrics.map((metric) =>
    buildSimpleSeries(firstPivot, metric, data)
  );

  const drilldownSeries = data
    .flatMap((node) =>
      buildDrilldownSimpleSeries(
        node.children || [],
        firstPivot,
        metrics,
        node[firstPivot.field_name]
      )
    )
    // As simple series does not have pivot2, we remove it from the drilldown series
    .map(removePivot2FromSeries);

  return {
    chart: {
      type: 'column',
      events: {
        drilldown: function (e) {
          // Workaround to know if the click was on a point or on a label
          const originalEvent = e.originalEvent as any;
          const wasClickOnPoint = !!originalEvent?.point;

          if (wasClickOnPoint) {
            if (wasClickOnPoint) {
              // Need to prevent drilldown
              // In theory we should only call preventDefault
              // But for some reason it prevents from future drilldown
              // https://github.com/highcharts/highcharts/issues/13448
              e.preventDefault();
              (this as any).ddDupes.length = 0;
              return;
            }
          }
        },
      },
    },
    series: series.sort(lineSeriesLast),
    xAxis: {
      type: 'category',
    },
    yAxis: getYAxisForChart(metrics),
    drilldown: {
      series: drilldownSeries,
      breadcrumbs: {
        formatter: breadcrumbsLabelFormatter,
      },
      allowPointDrilldown: false,
    },
  };
};

interface DrilldownData {
  fromParent: string;
  toChildren?: string;
}

const addPointToStackedSeries = (
  stackedSeriesMap: Map<string, SeriesColumnOptions>,
  serieData: {
    stack: string;
    serieName: string;
    valueType: string;
    yAxis?: number;
    metricId: string;
  },
  pointData: PointOptionsObject,
  drilldownData?: DrilldownData
) => {
  const stringedKey = `${serieData.serieName}-${serieData.stack}`;
  const serie = stackedSeriesMap.get(stringedKey);

  const pointWithDrilldown = {
    ...pointData,
    drilldown: drilldownData?.toChildren,
    custom: {
      pivot1Value: pointData.name,
    },
  };
  if (serie && serie.data) {
    serie.data.push(pointWithDrilldown);
  } else {
    const serieUsedAsLinking = Array.from(stackedSeriesMap.values()).find(
      (s) => s.name === serieData.serieName && !s.linkedTo
    );

    const newSeries: SeriesColumnOptions = {
      id: drilldownData?.fromParent ?? stringedKey,
      linkedTo: serieUsedAsLinking?.id,
      name: serieData.serieName,
      stack: serieData.stack,
      data: [pointWithDrilldown],
      color: HighchartsColors.mapColorBasedOnLabel(serieData.serieName, true),
      type: 'column',
      custom: {
        valueType: serieData.valueType,
        metricId: serieData.metricId,
        pivot2Value: serieData.serieName,
      },
      yAxis: serieData.yAxis,
    };
    stackedSeriesMap.set(stringedKey, newSeries);
  }
};

const getStackedSeries = (
  tree: PathAwareHierarchicalWidgetNode[],
  metrics: StackedMetricChartConfiguration[],
  pivots: ChartPivotConfiguration[],
  hierarchyPath: string = ''
): SeriesColumnOptions[] => {
  const stackedSeries = new Map<string, SeriesColumnOptions>();

  const pivot1 = pivots[0];
  const pivot1Field = pivot1.field_name;

  const pivot2 = pivots[1];
  const pivot2Field = pivot2.field_name;

  tree.forEach((node) => {
    const pivot1Value = node[pivot1Field];

    if (node.pivots) {
      node.pivots.forEach((childNode) => {
        const pivot2Value = childNode[pivot2Field];

        metrics.forEach((metric) => {
          const metricDisplayName = metric.display_name;

          const metricField = metric.field_name;

          const value = getFieldSafeValue(childNode, metricField);
          const serieData = {
            stack: metricDisplayName,
            serieName: pivot2Value,
            valueType: metric.type,
            yAxis: metric.yAxis,
            metricId: metricField,
          };

          const pointData = {
            y: value,
            name: pivot1Value,
          };

          const thereIsManagerHierarchy =
            pivot1.isManagerHierarchy || pivot2.isManagerHierarchy;

          const nodeChildren = node.children || [];
          const childNodeChildren = childNode.children || [];
          let drilldownData: DrilldownData | undefined;

          if (thereIsManagerHierarchy) {
            const hasChildren =
              nodeChildren.length > 0 || childNodeChildren.length > 0;
            const parsedHierarchyPath = hierarchyPath
              ? `${hierarchyPath}-`
              : '';

            const toChildren = pivot2.isManagerHierarchy
              ? `${parsedHierarchyPath}${pivot1Value}-${metricField}`
              : `${parsedHierarchyPath}${pivot1Value}-${pivot2Value}-${metricField}`;

            const isRoot = parsedHierarchyPath === '';
            const fromParent = `${
              isRoot ? 'Main-' : ''
            }${parsedHierarchyPath}${pivot2Value}-${metricField}`;

            drilldownData = {
              fromParent: fromParent,
              toChildren: hasChildren ? toChildren : undefined,
            };
          }

          addPointToStackedSeries(
            stackedSeries,
            serieData,
            pointData,
            drilldownData
          );
        });
      });
    }
  });

  return Array.from(stackedSeries.values());
};

const buildStackedSeriesDrilldown = (
  tree: PathAwareHierarchicalWidgetNode[],
  metrics: StackedMetricChartConfiguration[],
  pivots: ChartPivotConfiguration[],
  hierarchyPath: string = ''
): SeriesColumnOptions[] =>
  tree.flatMap((node) => {
    if (!node.children) {
      return [];
    }
    const parsedHierarchyPath = hierarchyPath ? `${hierarchyPath}-` : '';

    const idForChildren = `${parsedHierarchyPath}${node[pivots[0].field_name]}`;

    const stackedSeries = getStackedSeries(
      node.children,
      metrics,
      pivots,
      idForChildren
    );

    const childrenDrilldownSeries = buildStackedSeriesDrilldown(
      node.children,
      metrics,
      pivots,
      idForChildren
    );

    return [...stackedSeries, ...childrenDrilldownSeries];
  });

const buildDrilldownForStackedSeriesSecondPivotedWithManagerHierarchy = (
  tree: PathAwareHierarchicalWidgetNode[],
  metrics: StackedMetricChartConfiguration[],
  pivots: ChartPivotConfiguration[]
): SeriesOptionsType[] => {
  const pivot1 = pivots[0];
  const pivot2 = pivots[1];
  return tree.flatMap((node) => {
    if (!node.pivots) {
      return [];
    }

    const simpleMetrics: SimpleMetricChartConfiguration[] = metrics.map(
      (metric) => ({
        ...metric,
        chartType: VisualizationType.Column,
      })
    );

    const pivot1Value = node[pivot1.field_name];

    return (
      buildDrilldownSimpleSeries(
        node.pivots || [],
        pivot2,
        simpleMetrics,
        pivot1Value
      )
        // As we used the second pivot to build the drilldown instead of the first one
        // We need to reverse the pivot values saved in the custom object
        .map((serie) => reversePivotValues(serie, pivot1Value))
    );
  });
};

export const getStackedVisualizationChart = (
  tree: PathAwareHierarchicalWidgetNode[],
  metrics: StackedMetricChartConfiguration[],
  pivots: ChartPivotConfiguration[]
): Highcharts.Options => {
  const series = getStackedSeries(tree, metrics, pivots);

  const pivot1 = pivots[0];
  const pivot2 = pivots[1];

  const pivot1IsManagerHierarchy = pivot1.isManagerHierarchy;
  const pivot2IsManagerHierarchy = pivot2.isManagerHierarchy;
  let drilldownSeries: SeriesOptionsType[] = [];

  if (pivot1IsManagerHierarchy) {
    drilldownSeries = buildStackedSeriesDrilldown(tree, metrics, pivots);
  } else if (pivot2IsManagerHierarchy) {
    drilldownSeries =
      buildDrilldownForStackedSeriesSecondPivotedWithManagerHierarchy(
        tree,
        metrics,
        pivots
      );
  }

  return {
    chart: {
      type: 'column',
      events: {
        drilldown: function (e) {
          // Workaround to know if the click was on a point or on a label
          const originalEvent = e.originalEvent as any;
          const wasClickOnPoint = !!originalEvent?.point;

          if (wasClickOnPoint) {
            // Need to prevent drilldown
            // In theory we should only call preventDefault
            // But for some reason it prevents from future drilldown
            // https://github.com/highcharts/highcharts/issues/13448
            e.preventDefault();
            (this as any).ddDupes.length = 0;
            return;
          }
          const hasStackingEnabled =
            this.options.plotOptions?.column?.stacking === 'normal';

          if (pivot2IsManagerHierarchy && hasStackingEnabled) {
            this.update({
              plotOptions: {
                column: {
                  stacking: undefined,
                },
              },
            });
          }
          (e as any).defaultPrevented = false;
        },

        drillup: function (e) {
          const hasStackingDisabled =
            this.options.plotOptions?.column?.stacking !== 'normal';
          // Workaround as there is no way to know if we are going to the first level
          // So we check for the ID of the series, first level id looks like "-someString"
          const goingToFistLevel = e.seriesOptions?.id?.includes('Main-');
          if (
            pivot2IsManagerHierarchy &&
            hasStackingDisabled &&
            goingToFistLevel
          ) {
            this.update({
              plotOptions: {
                column: {
                  stacking: 'normal',
                },
              },
            });
          }
        },
      },
    },
    series: series,
    xAxis: {
      type: 'category',
    },
    yAxis: getYAxisForChart(metrics),
    drilldown: {
      series: drilldownSeries,
      breadcrumbs: {
        formatter: breadcrumbsLabelFormatter,
      },
      allowPointDrilldown: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
  };
};

const getFormattedNumberForType = (
  value: number,
  type: string,
  currencyCode: string,
  isNegative?: boolean
): string => {
  const getFormattedAbsoluteValue = () => {
    switch (type) {
      case 'money':
        return formatAmount(value, currencyCode);
      case 'percentage':
        return formatPercentage(value, 0);
      default:
        return formatNumber(value);
    }
  };
  const formattedAbsoluteValue = getFormattedAbsoluteValue();
  return isNegative ? `- ${formattedAbsoluteValue}` : formattedAbsoluteValue;
};

const getMetricNameFromStack = (stack?: string) => {
  if (!stack) {
    return;
  }

  const splittedStack = stack.split('-');
  return splittedStack[splittedStack.length - 1];
};

const reversePivotValues = (
  serie: SeriesColumnOptions | SeriesLineOptions,
  pivot1Value: any
) => {
  const data = serie?.data?.map((point) => {
    if (!point || Array.isArray(point) || typeof point !== 'object') {
      return point;
    }

    return {
      ...point,
      custom: {
        ...point.custom,
        pivot1Value,
        pivot2Value: point.name,
      },
    };
  });

  return {
    ...serie,
    data,
  };
};

function tooltipFormatter(
  context: TooltipFormatterContextObject,
  currencyCode: string
) {
  const isStackedChart = !!context.series.options.stack;
  const metricName = isStackedChart
    ? getMetricNameFromStack(String(context.series.options.stack))
    : context.series.name;

  const customPointOptions = context.point.options.custom as
    | RevBiCustomPoint
    | undefined;

  const customSeriesOptions = context.point.series.options.custom as
    | RevBiCustomSeries
    | undefined;

  const pivotValue =
    customSeriesOptions?.pivot2Value || customPointOptions?.pivot1Value;

  const valueType = customSeriesOptions?.valueType ?? 'number';

  const isNegative = customPointOptions?.isNegative;

  const formattedNumber =
    typeof context.point.y === 'number'
      ? getFormattedNumberForType(
          context.point.y,
          valueType,
          currencyCode,
          !!isNegative
        )
      : context.point.y;

  const formattedPivotValue = pivotValue ? `${pivotValue} <br/>` : '';

  const formattedMetricName = `${metricName} <br/>`;

  return `${formattedMetricName} ${formattedPivotValue} ${formattedNumber}`;
}

function yAxisLabelFormatter(
  context: AxisLabelsFormatterContextObject,
  currencyCode: string
) {
  const customSeriesOptions = context.axis.series[0].options
    .custom as RevBiCustomSeries;
  const valueType = customSeriesOptions.valueType;
  const value = context.value;

  return typeof value === 'number'
    ? getFormattedNumberForType(value, valueType, currencyCode)
    : value.toString();
}

function breadcrumbsLabelFormatter(
  // This is actually a BreadcrumbOptions, its wrongly typed in highcharts as an Event
  // Have to use any here to avoid type errors but also being able to assert to the correct type
  badTypedBreadcrumbsOption: any
) {
  const breadcrumbOptions = badTypedBreadcrumbsOption as BreadcrumbOptions;
  const isFirstLevel = !breadcrumbOptions?.level;
  if (isFirstLevel) {
    return 'Main';
  } else {
    return `${breadcrumbOptions.levelOptions.name}`;
  }
}

export const getMetricChartConfigurations = (
  metrics: WidgetMetricConfiguration[],
  metricToChartType: BIMetricToChartType[]
): MetricChartConfiguration[] =>
  metrics.map((metric) => {
    const chartType = getChartTypeForMetric(
      metric.field_name,
      metricToChartType
    );
    return {
      ...metric,
      chartType,
    };
  });

export const getPivotChartConfigurations = (
  pivots: WidgetPivotConfiguration[],
  hierarchyAlias: string[]
): ChartPivotConfiguration[] =>
  pivots.map((pivot) => ({
    ...pivot,
    isManagerHierarchy: hierarchyAlias.includes(
      pivot.field_name.replace('$', '.')
    ),
  }));

const lineSeriesLast = (a: SeriesOptionsType, b: SeriesOptionsType) => {
  if (a.type === 'line') {
    return 1;
  }
  if (b.type === 'line') {
    return -1;
  }
  return 0;
};

const getMergeWithBaseChart =
  (baseChart: Highcharts.Options, yAxisLabelConfig: YAxisLabelsOptions) =>
  (specificChart: Highcharts.Options) => {
    const mergedChart = mergeDeepRight(
      baseChart,
      specificChart
    ) as Highcharts.Options;

    if (mergedChart.yAxis && Array.isArray(mergedChart.yAxis)) {
      mergedChart.yAxis.forEach((yAxis) => {
        yAxis.labels = yAxisLabelConfig;
      });
    }

    return mergedChart;
  };

const getOnClickData = (
  event: PointClickEventObject
): ChartClickedData | undefined => {
  event.preventDefault();

  const clickedPoint = event.point;
  if (clickedPoint) {
    const clickedSeriesData = clickedPoint.series.options
      .custom as RevBiCustomSeries;
    const clickedPointData = clickedPoint.options.custom as RevBiCustomPoint;
    const pivot1Data = clickedPointData.pivot1Value;
    // In case it is a stacked chart with user as second pivot
    // Pivot 2 field will be on the point and not on the series
    const pivot2Data =
      clickedPointData.pivot2Value || clickedSeriesData.pivot2Value;
    const numberClicked = Number(clickedPoint.y);
    return {
      metricId: clickedSeriesData.metricId,
      pivotValues: [pivot1Data, pivot2Data],
      y: numberClicked,
    };
  }
};

const sortTree = (
  tree: PathAwareHierarchicalWidgetNode[],
  pivots: WidgetPivotConfiguration[]
) => {
  if (!pivots) {
    return [];
  }
  const currentPivot = pivots.slice(-1)[0];
  const restOfPivots = pivots.slice(0, -1);
  const fieldToSort = currentPivot.field_name;
  const fieldToSortType = currentPivot.type;
  const isDate = fieldToSortType === 'date';
  const parseIfDate = (x: unknown) => (isDate ? new Date(String(x)) : x);
  const byParsedField = compose(parseIfDate, path([fieldToSort]));

  const sortBy = sort(ascend(byParsedField));

  const sortedChildrenNodes: PathAwareHierarchicalWidgetNode[] = tree.map(
    (n) => ({
      ...n,
      ...(n.children && {
        children: sortTree(n.children, pivots),
      }),
      ...(n.pivots && {
        pivots: sortTree(n.pivots, restOfPivots),
      }),
    })
  );

  return sortBy(sortedChildrenNodes);
};

export const getHighChartOptions = (
  widget: HierarchicalWidget,
  currencyCode: string,
  metricToChartType: BIMetricToChartType[],
  hierarchyAlias: string[],
  onDataClick: OnChartDataClick,
  updateTotal: (levelObject: BreadcrumbOptions[]) => void
): Highcharts.Options | undefined => {
  const metrics = widget.metricConfigurations;
  const pivots = widget.pivotConfigurations;
  const data = sortTree(widget.tree, pivots);
  const yAxisLabelConfig: YAxisLabelsOptions = {
    formatter: function (this) {
      return yAxisLabelFormatter(this, currencyCode);
    },
  };

  const BASE_CHART: Highcharts.Options = {
    title: {
      text: '',
    },
    chart: {
      alignThresholds: true,
      events: {
        render: (event) => {
          const highChart = event.target as unknown as Highcharts.Chart;
          // @ts-ignore
          updateTotal(highChart?.breadcrumbs?.list || []);
        },
      },
    },
    tooltip: {
      formatter: function (this: TooltipFormatterContextObject) {
        return tooltipFormatter(this, currencyCode);
      },
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: function (this, event) {
              const onClickData = getOnClickData(event);
              if (onClickData) {
                onDataClick(onClickData);
              }
            },
          },
        },
        //@ts-ignore doc: https://api.highcharts.com/highcharts/plotOptions.column.minPointLength
        minPointLength: 2,
      },
    },
  };

  const mergeWithBaseChart = getMergeWithBaseChart(
    BASE_CHART,
    yAxisLabelConfig
  );

  const metricChartConfigurations = getMetricChartConfigurations(
    metrics,
    metricToChartType
  );

  const pivotChartConfigurations = getPivotChartConfigurations(
    pivots,
    hierarchyAlias
  );

  if (someIsPieChart(metricChartConfigurations)) {
    const onlyPivot = pivotChartConfigurations[0];
    // Adding ! as already check if there is a pie chart
    const onlyMetric = findPieChart(metricChartConfigurations)!;
    const pieChartOptions = getPieChartConfiguration(
      data,
      onlyMetric,
      onlyPivot
    );
    return mergeWithBaseChart(pieChartOptions);
  }

  if (someIsSimpleChart(metricChartConfigurations)) {
    const metricsForChart = addYAxisIndex(
      filterSimpleVisualizationCharts(metricChartConfigurations)
    );

    const simpleChart = getSimpleVisualizationChart(
      metricsForChart,
      pivotChartConfigurations,
      data
    );

    return mergeWithBaseChart(simpleChart);
  }

  if (someIsStackedChart(metricChartConfigurations) && pivots.length === 2) {
    const metricsForChart = addYAxisIndex(
      filterStackedVisualizationCharts(metricChartConfigurations)
    );
    const stackedChart = getStackedVisualizationChart(
      data,
      metricsForChart,
      pivotChartConfigurations
    );

    return mergeWithBaseChart(stackedChart);
  }

  return undefined;
};
